import React, { FC, useEffect } from 'react';
import { ProviderProps } from '../types';

const Finix: FC<ProviderProps> = ({ handleOnChange }) => {
  useEffect(() => {
    handleOnChange('card');
  }, [handleOnChange]);

  return <></>;
};

export default Finix;
