import React, { FC } from 'react';
import Modal from 'components/Modal';
import './style.scss';

interface UploadErrorModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  fileName: string;
}

const UploadErrorModal: FC<UploadErrorModalProps> = ({ fileName, showModal, setShowModal }: UploadErrorModalProps) => {
  return (
    <Modal
      visible={showModal}
      handleOnCancel={() => setShowModal(false)}
      handleOnConfirm={() => setShowModal(false)}
      className="upload-error-modal"
      confirmlabel="Ok"
      header="Uh oh!">
      <p>We were unable to upload &quot;{fileName}&quot;.</p>
      <p>Supported image formats are JPEG, JPG, WEBP, SVG, PNG and GIF.</p>
      <p>
        The maximum accepted image resolution is 100,000,000 pixels. For example it can either be 10,000px x 10,000px or
        5,000px x 20,000px
      </p>
      <p>The image may be declined if content is considered inappropriate.</p>
    </Modal>
  );
};

export default UploadErrorModal;
