import React, { FC } from 'react';
import clsx from 'clsx';
import { useSelect } from 'downshift';
import { canModifyOrRemoveMemberRole } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import { checkUserRole } from 'components/ProtectedComponent';
import ICONS from 'components/Icons';
import { MapType } from './index';
import { OrganizationRoles } from 'api/data/user/types';
import './style.scss';

const mapRoleText: MapType = {
  OWNER: 'Organization Owner',
  ADMIN: 'Organization Admin',
  SPACE: 'Space Staff',
};

type OrganizationRoleProps = {
  value: string;
  onChange: (role: string) => void;
};

type Role = {
  id: string;
  name: string;
  description: string;
};

const organizationRoles: Role[] = [
  {
    id: 'OWNER',
    name: 'Organization Owner',
    description: 'Can access all spaces, settings and payouts.',
  },
  {
    id: 'ADMIN',
    name: 'Organization Admin',
    description: 'Can access all spaces and settings.',
  },
  { id: 'SPACE', name: 'Space Staff', description: 'Can only access the spaces they are members of.' },
];

const OrganizationRole: FC<OrganizationRoleProps> = ({ value, onChange }) => {
  const { currentOrg } = useCurrentOrganization();
  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items: organizationRoles,
    itemToString(item) {
      return item ? item.id : '';
    },
    selectedItem: organizationRoles.find(item => item.id === value),
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem?.id || ''),
  });

  const canAddOrModifyOrgRole = checkUserRole('ADD_OR_MODIFY_ORG_ROLE', currentOrg?.currentUserRole);
  const allowModifyMemberRole = canModifyOrRemoveMemberRole(value as OrganizationRoles, currentOrg?.currentUserRole);

  return (
    <div className="selector organization-role">
      <span {...getToggleButtonProps()} className={clsx('role-name', { 'disabled-role': !allowModifyMemberRole })}>
        {mapRoleText[value]}
        {allowModifyMemberRole && ICONS['arrow_down']}
      </span>
      <ul {...getMenuProps()}>
        {isOpen &&
          allowModifyMemberRole &&
          organizationRoles.map((item, index) => {
            if (item.id === 'OWNER' && !canAddOrModifyOrgRole) return null;

            return (
              <li key={item.id} {...getItemProps({ item, index })}>
                <div>
                  <span className="name">{item.name}</span>
                  <span className="description">{item.description}</span>
                </div>
                <div>{ICONS['check']}</div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default OrganizationRole;
