import React, { FC, useState, useEffect } from 'react';
import { useApolloClient, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { validateEmail } from 'utils';
import CheckoutCard from './Checkout';
import FinixCard from './Finix';
import Email from '../Input/Email';
import Input from '../Input';
import PaymentError from '../Error/PaymentError';
import StripeCard from './Stripe';
import { Response, User } from 'api/data/response/types';
import { OnSubmitFunction, PaymentErrorProps } from 'api/data/payment/types';
import './style.scss';

export interface CreditCardProps {
  onSubmit: OnSubmitFunction;
  onResponseChanged?: (response: Response) => void;
  pageId?: string;
  buttonText?: string;
  paymentError?: PaymentErrorProps;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  provider?: string;
  disableInputEmail?: boolean;
  response: Response;
}

const CreditCard: FC<CreditCardProps> = ({
  onSubmit,
  onResponseChanged,
  pageId,
  buttonText,
  paymentError,
  setLoading,
  loading,
  provider,
  disableInputEmail,
  response,
}) => {
  const [user, setUser] = useState<User>(response.user ? response.user : { email: '', zipcode: '', fullName: '' });
  const [errorMessage, setErrorMessage] = useState('');

  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

  useEffect(() => {
    return function cleanup() {
      setLoading(false);
    };
  }, [setLoading]);

  const handleOnBlur = () => {
    onResponseChanged && onResponseChanged({ ...response, user });
  };

  const handleResponseUser = (value: string, field: string) => {
    setUser({ ...user, [field]: value });
  };

  const isEmailInvalid = !!user?.email && !validateEmail(user?.email);

  const showButton =
    !user ||
    !user.fullName.trim() ||
    !user.zipcode ||
    user.zipcode.length < 5 ||
    loading ||
    (!!pageId && response.order.totalCents === 0);

  const cardProps = {
    response: { ...response, user },
    handleResponseUser,
    showButton,
    setErrorMessage,
    client,
    setLoading,
    buttonText,
    onSubmit,
    loading,
    handleOnBlur,
  };

  const cardElements: { [key: string]: JSX.Element } = {
    stripe: <StripeCard {...cardProps} />,
    checkout: <CheckoutCard {...cardProps} />,
    finix: <FinixCard {...cardProps} />,
  };

  return (
    <div className="credit-card-container">
      {!loading && errorMessage && <PaymentError errorMessage={errorMessage} title={paymentError?.title} />}
      <Email
        onChange={({ target }) => handleResponseUser(target.value, 'email')}
        value={user?.email || ''}
        name="input-mail"
        disabled={disableInputEmail}
        onBlur={({ target }) => {
          handleResponseUser(target.value, 'email');
          handleOnBlur();
        }}
        rules={{
          hasError: isEmailInvalid,
          errorMessage: 'Please enter a valid email.',
        }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      {validateEmail(user?.email || '') && (
        <div>
          {provider !== 'finix' && (
            <Input
              label="Full name"
              placeholder="Full name"
              onChange={({ target }) => handleResponseUser(target.value, 'fullName')}
              onBlur={() => handleOnBlur()}
              value={user?.fullName}
              name="full-name"
              type="text"
            />
          )}
          {!!provider && cardElements[provider]}
        </div>
      )}
    </div>
  );
};

export default CreditCard;
