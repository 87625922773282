import axios from 'axios';
import { APP_CONFIG } from './config';

export interface FileUploadResponse {
  file_handle: string;
  file_name?: string;
  url: string;
}

const uploadFile = (
  file: File,
  type: 'IMAGE' | 'FILE',
  imageType?: string,
  pageId?: string,
  requestId?: string,
  validate?: string,
): Promise<FileUploadResponse> => {
  const config = {
    withCredentials: true,
  };

  const formData = new FormData();
  formData.append('file_upload', file);
  if (pageId) formData.append('page_id', pageId);
  if (requestId) formData.append('request_id', requestId);
  if (imageType) formData.append('type', imageType);
  if (validate) formData.append('validate', validate);

  const uri = APP_CONFIG[`${type}_UPLOAD_ENDPOINT`];
  return axios.post<FileUploadResponse>(uri, formData, config).then(response => response.data);
};

export default uploadFile;
