import { checkUserRole } from 'components/ProtectedComponent';
import { OrganizationRoles } from 'api/data/user/types';

const canModifyOrRemoveMemberRole = (memberRole: OrganizationRoles, currentUserRole?: OrganizationRoles) => {
  if (memberRole !== 'OWNER') return true;

  return checkUserRole('ADD_OR_MODIFY_ORG_ROLE', currentUserRole);
};

export default canModifyOrRemoveMemberRole;
