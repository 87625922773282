import React, { FC, useRef, useState } from 'react';
import { onImageInputClick } from '~/utils';
import ICONS from '~/components/Icons';
import IconButton from '~/components/Button/IconButton';
import UploadImageWrapper from '~/components/UploadImage';
import UploadMenu from '~/components/UploadMenu';

type ProfilePictureProps = {
  name: string;
  pictureUrl: string | null;
  removeImage: (attr: string) => void;
  handleOnUploadImage: (file: File, attr: string) => Promise<void>;
};

const ProfilePicture: FC<ProfilePictureProps> = ({ name, pictureUrl, handleOnUploadImage, removeImage }) => {
  const pictureInput = useRef<HTMLInputElement>(null);
  const refImages = useRef<HTMLDivElement>(null);
  const refOutside = useRef<HTMLElement>(null);

  const [imageUploading, setImageUploading] = useState('');

  return (
    <div className="img-header" ref={refImages}>
      <UploadImageWrapper
        image={{
          name,
          label: name,
          file: pictureUrl || '',
          ref: pictureInput,
        }}
        imageUploading={imageUploading}
        handleOnRemoveImage={removeImage}
        handleOnUploadImage={handleOnUploadImage}
        setImageUploading={setImageUploading}
        showImage={!imageUploading}>
        <UploadMenu outsideRef={refOutside} inputRef={pictureInput} deletePhoto={() => removeImage(name)}>
          Edit photo
        </UploadMenu>
      </UploadImageWrapper>

      {!pictureUrl && !imageUploading && (
        <div className="upload-picture">
          <IconButton className="upload-text" icon={'file_upload'} onClick={() => onImageInputClick(pictureInput)}>
            <span>Upload Profile Picture</span>
          </IconButton>
        </div>
      )}

      {!!imageUploading && (
        <div className="upload-picture">
          <span className="upload-text">{ICONS['spinner']} Uploading...</span>
        </div>
      )}
    </div>
  );
};

export default ProfilePicture;
