import React, { FC, ReactNode, RefObject, useRef, useState } from 'react';
import { onImageInputClick, validateImageType } from 'utils';
import IconButton from 'components/Button/IconButton';
import UploadErrorModal from 'components/UploadErrorModal';
import UploadMenu from 'components/UploadMenu';
import { ImageType } from 'api/data/pages/types';

interface InputImageProps {
  image: ImageType;
  imageUploading: string;
  handleOnRemoveImage: (imageName: string) => void;
  handleOnUploadImage: (file: File, attr: string) => Promise<unknown>;
  setImageUploading: (imageUploading: string) => void;
  showImage?: boolean;
  children?: ReactNode;
}

const UploadImageWrapper: FC<InputImageProps> = ({
  image,
  imageUploading,
  handleOnRemoveImage,
  handleOnUploadImage,
  setImageUploading,
  showImage = true,
  children,
}) => {
  const [uploadError, setUploadError] = useState(false);
  const [fileName, setFileName] = useState('');
  const refOutside = useRef<HTMLElement>(null);
  const isLoading = imageUploading === image.label;

  const onImageInputChange = async (inputRef: RefObject<HTMLInputElement>, attr: string) => {
    if (inputRef && inputRef.current && inputRef.current.files) {
      setFileName(inputRef.current.files[0].name);
      if (validateImageType(inputRef.current.files[0].type)) {
        setImageUploading(attr.slice(0, 5));
        try {
          await handleOnUploadImage(inputRef.current.files[0], attr);
        } catch {
          setUploadError(true);
        } finally {
          setImageUploading('');
        }
      } else {
        setUploadError(true);
      }
    }
  };

  return (
    <>
      {image.file && showImage && (
        <div className={image.label}>
          <img src={image.file} alt={`page ${image.label}`} />
          <div className={`${image.label}-button`}>
            {children || (
              <>
                {isLoading && <span className="upload-menu upload-text">Uploading...</span>}
                {!isLoading && image.imageIsEmpty && (
                  <div className="upload-menu">
                    <IconButton
                      className="upload-text"
                      icon={image.uploadIcon || 'image_upload'}
                      onClick={() => onImageInputClick(image.ref)}>
                      <span className="button-text">Add {image.label}</span>
                    </IconButton>
                  </div>
                )}
                {!isLoading && !image.imageIsEmpty && (
                  <UploadMenu
                    outsideRef={refOutside}
                    inputRef={image.ref}
                    deletePhoto={() => handleOnRemoveImage(image.name)}>
                    Edit {image.label}
                  </UploadMenu>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        ref={image.ref}
        style={{ display: 'none' }}
        onChange={() => void onImageInputChange(image.ref, image.name)}
        data-testid={`${image.label}-input`}
      />
      <UploadErrorModal showModal={uploadError} setShowModal={setUploadError} fileName={fileName} />
    </>
  );
};

export default UploadImageWrapper;
