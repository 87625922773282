import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import clsx from 'clsx';
import useClickAway from '~/hooks/useClickAway';
import useEscape from '~/hooks/useEscape';
import useScrollLock from '~/hooks/useScrollLock';
import ICONS from '../Icons';
import './style.scss';

export interface SideModalProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  isOpen: boolean;
  toggle: (nextValue?: boolean) => void;
  title?: string;
  className?: string;
  closeOnOutsideClick?: boolean;
  outsideClickCallBack?: () => void;
}

export default function SideModal({
  children,
  isOpen,
  toggle,
  title,
  header,
  className,
  closeOnOutsideClick = true,
  outsideClickCallBack,
}: SideModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickAway = () => {
    closeOnOutsideClick && toggle(false);
    outsideClickCallBack && outsideClickCallBack();
  };

  useClickAway(modalRef, handleClickAway);
  useEscape(handleClickAway);
  useScrollLock(isOpen);

  return isOpen
    ? createPortal(
        <FocusLock disabled={!isOpen}>
          <div className="overlay"></div>
          <div ref={modalRef} className={clsx('side-modal', className)} aria-label="side modal">
            {header ? header : <DefaultHeader onClose={() => toggle(false)} title={title} />}
            <div className="side-modal-content">{children}</div>
          </div>
        </FocusLock>,
        document.body,
      )
    : null;
}

const DefaultHeader = ({ onClose, title }: { onClose: () => void; title?: string }) => (
  <>
    <div className="default-header">
      <span>{title}</span>
      <button className="button-link close-button" aria-label="close button" onClick={onClose}>
        {ICONS['close']}
      </button>
    </div>
    <div className="horizontal-line" />
  </>
);
