import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { APP_CONFIG } from 'api/config';
import { isProduction } from 'utils';
import { createSetupIntent } from 'api/data/payment';
import Input from '../Input';
import { CardProps } from './types';
import { PaymentErrorType, PaymentMethodType } from 'api/data/payment/types';
import './style.scss';

type ResParam = { data: { id: string } };
export type FinixCallback = (err: string | null, res: ResParam) => void;

type FinixForm = {
  submit: (env: string, appId: string, callback: FinixCallback) => void;
};

const env = isProduction() ? 'live' : 'sandbox';
const formConfig = {
  hideErrorMessages: true,
  showLabels: true,
  showPlaceholders: true,
  showAddress: true,
  fonts: [
    {
      fontFamily: 'Poppins, sans-serif',
    },
  ],
  hideFields: [
    'name',
    'address_line1',
    'address_line2',
    'address_city',
    'address_state',
    'address_region',
    'address_country',
  ],
  requiredFields: ['address_postal_code'],
  styles: {
    default: {
      color: '#555',
      border: '0',
      borderBottom: '1px solid #bfbfbf',
      fontFamily: "'Poppins', sans-serif",
      fontSize: '14px',
      boxShadow: '0px',
      backgroundColor: '',
    },
    error: {
      borderBottom: '1px solid #c73e3e',
    },
  },
};

const FinixCard: FC<CardProps> = ({
  response,
  handleResponseUser,
  setLoading,
  setErrorMessage,
  onSubmit,
  buttonText,
  loading,
  client,
  handleOnBlur,
}) => {
  const [formIsLoading, setFormIsLoading] = useState(true);
  const [hasError, setHasError] = useState(true);
  const formRef = useRef<FinixForm | null>(null);

  useEffect(() => {
    if (window.Finix && formRef) {
      formRef.current = window.Finix.CardTokenForm('form-elements', {
        ...formConfig,
        onLoad: () => {
          setFormIsLoading(false);
        },
        onUpdate: function (_state, _binInfo, formHasErrors) {
          setHasError(formHasErrors);
        },
      });
    }
  }, [setHasError, setFormIsLoading]);

  const handleSubmit = async (err: string | null, res: ResParam) => {
    if (err) {
      setErrorMessage(err);
      setLoading(false);
    } else {
      const tokenData = res.data || {};
      const token = tokenData.id;

      try {
        const { paymentMethodId } = await createSetupIntent(
          {
            email: response?.user?.email || '',
            responseId: response.id || '',
            token: token,
          },
          client,
        );

        await onSubmit({
          paymentMethodId,
          paymentMethodType: PaymentMethodType.CARD,
          response,
        });

        setLoading(false);
      } catch (error) {
        if (typeof error === 'string') setErrorMessage(error);
        else {
          setErrorMessage((error as PaymentErrorType).message);
        }
        setLoading(false);
      }
    }
  };

  const submitCardForm = (event: SyntheticEvent) => {
    event.preventDefault();

    if (!formRef.current) return;

    setLoading(true);
    setErrorMessage('');

    void formRef.current.submit(env, APP_CONFIG.FINIX, (err, res) => void handleSubmit(err, res));
  };

  return (
    <form onSubmit={event => void submitCardForm(event)} className="payment-method" aria-label="credit card form">
      {/* We need to wait for the form to load because when it does, 
      the input loses focus, and the user might still be typing at that moment. */}
      {!formIsLoading && (
        <Input
          label="Full name"
          placeholder="Full name"
          onChange={({ target }) => handleResponseUser(target.value, 'fullName')}
          onBlur={() => handleOnBlur()}
          value={response.user?.fullName}
          name="full-name"
          type="text"
        />
      )}
      <div id="form-elements" style={{ display: formIsLoading ? 'none' : 'block' }}></div>
      {formIsLoading ? (
        <span>Loading...</span>
      ) : (
        <button type="submit" disabled={hasError}>
          {loading ? 'Processing...' : buttonText}
        </button>
      )}
    </form>
  );
};

export default FinixCard;
