import React, { FC, useState } from 'react';
import { useLocation } from 'wouter';
import Checkout from './Checkout';
import Finix from './Finix';
import Stripe from './Stripe';
import appleIcon from 'assets/apple_logo.svg';
import googleIcon from 'assets/google-pay-mark.png';
import linkIcon from 'assets/stripe_link_logo.svg';
import { WalletProps } from './types';
import './style.scss';

const wallets: { [key: string]: { label: string; icon: string; className?: string } } = {
  apple: { label: 'Apple Pay', icon: appleIcon },
  google: { label: 'Google Pay', icon: googleIcon, className: 'google-pay' },
  link: { label: 'by Stripe', icon: linkIcon },
};

const Wallet: FC<WalletProps> = ({ handleOnChange, stripe, provider, ...props }) => {
  const [walletType, setWalletType] = useState('');
  const [, setLocation] = useLocation();

  const walletOption = () => (
    <div className="payment-fields-container">
      {walletType && (
        <label htmlFor="wallet" className="payment-label">
          <img src={wallets[walletType].icon} alt="Wallet" className={wallets[walletType].className} />
          {wallets[walletType].label}
        </label>
      )}
      <input
        id="wallet"
        type="radio"
        name="payment"
        defaultChecked
        onChange={() => {
          handleOnChange('wallet');
        }}
      />
    </div>
  );

  const redirectAfterPayment = (succeeded: boolean, responseId: string) => {
    if (succeeded) {
      setLocation(`/submissions/${responseId}?type=payment`);
    } else {
      setLocation(`/submissions/${responseId}?type=payment_sent`);
    }
  };

  const commonProps = {
    walletType,
    setWalletType,
    handleOnChange,
    redirectAfterPayment,
  };

  const WalletByProvider: { [key: string]: JSX.Element } = {
    stripe: (
      <Stripe stripe={stripe} {...commonProps} {...props}>
        {walletOption()}
      </Stripe>
    ),
    checkout: (
      <Checkout {...commonProps} {...props}>
        {walletOption()}
      </Checkout>
    ),
    finix: (
      <Finix {...commonProps} {...props}>
        {walletOption()}
      </Finix>
    ),
  };

  if (provider === 'stripe') {
    return (
      <Stripe stripe={stripe} {...commonProps} {...props}>
        {walletOption()}
      </Stripe>
    );
  }

  return WalletByProvider[provider];
};

export default Wallet;
